<template>
  <div class="monitor_page">
    <sub-form
      ref="newForm"
      :visible="showForm"
      :title="sftitle"
      :formdata="sfdata"
      @cancel="hideForm"
      @create="hideForm(true)"
    />
    <sub-form-reg
      ref="subformreg"
      :visible="visibleRegedit"
      @cancel="() => visibleRegedit = false"
      @create="onRegedit"
    />
    <view-monitor 
      v-if="visibleMonitor"
      :id="monitorId"
      @cancel="() => visibleMonitor = false"
    />
    <div class="table_ctrl">
      <a-space class="operator">
        <b style="font-size: 24px;">监控管理</b>
        <span>（ 共 {{ total }} 条数据 ）</span>
        <a-button @click="addNew" type="primary"
          ><a-icon type="plus-circle" theme="filled" />新增监控</a-button
        >
        <a-button @click="regedit" type="primary"
          >注册萤石</a-button
        >
        <!-- <a-button @click="test" type="primary"
          >测试</a-button
        > -->
      </a-space>
      <a-space class="operator2">
        <a-select
          class="operator2_select"
          style="width: 120px"
          placeholder="状态"
          allowClear
          @change="(e) => onSearchChange('status', e)"
        >
          <a-select-option value="0">
            在线
          </a-select-option>
          <a-select-option value="1">
            离线
          </a-select-option>
        </a-select>
        <!-- <a-button type="primary" icon="reload" @click="update" /> -->
        <a-input-search
          class="operator2_search"
          v-model="keyword"
          placeholder="输入序列号、监控名称"
          allow-clear
          @search="onSearch"
        >
          <a-button type="primary" icon="search" slot="enterButton">
            搜索
          </a-button>
        </a-input-search>
      </a-space>
    </div>
    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      :pagination="{
        total: total,
        pageSize: pagination.pageSize,
      }"
      @clear="onClear"
      @change="onChange"
      @selectedRowChange="onSelectChange"
    >
      <div slot="description" slot-scope="{ text }">
        {{ text }}
      </div>
      <div slot="status" slot-scope="{ record }">
        <span v-if="record.status === 1" style="color:green">
          在线</span
        >
        <span v-else style="color:red">离线</span>
        <!-- <span v-else style="color:red">故障</span> -->
      </div>
      <div slot="action" slot-scope="{ record }">
        <!-- 
        <a-dropdown placement="bottomRight">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <a-icon type="bars" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="0">
              <a @click="editForm(record)" style="margin-right: 8px">
                <a-icon type="form" /> 编辑
              </a>
            </a-menu-item>
            <a-menu-item key="1">
              <a  @click="deleteRecord(record)"  style="margin-right: 8px" >
                <a-icon type="delete" /> 删除
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown> -->
        <a style="margin-right: 8px" @click="handlePreview(record.preview)" :disabled="record.preview === null">
          预览
        </a>
        <a style="margin-right: 8px" @click="viewRecord(record)">
          <a-icon type="zoom-in" /> 查看
        </a>
        <a style="margin-right: 8px" @click="editForm(record)">
          <a-icon type="edit" />编辑
        </a>
        <a style="margin-right: 8px" @click="deleteRecord(record)">
          <a-icon type="delete" />删除
        </a>
      </div>
      <template slot="statusTitle">
        <a-icon @click.native="onStatusTitleClick" type="info-circle" />
      </template>
    </standard-table>
    <popup-form
      :visible="visibleConfirm"
      :width="460"
      :maskClosable="false"
      title="确定删除？"
      oktext="确定"
      :destroyOnClose="true"
      @cancel="
        () => {
          this.visibleConfirm = false;
        }
      "
      @ok="
        () => {
          this.visibleConfirm = false;
          this.confirmDelete();
        }
      "
    >
      确定删除监控 <span style="color:#1b79d7;">{{ visibleConfirmSn }}</span> ？
    </popup-form>
    <a-modal
        :visible="previewVisible"
        :footer="null"
        @cancel="handleCancel"
      >
        <img
          alt="example"
          style="width: 100%"
          :src="deviceimg_prev"
        />
      </a-modal>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import PopupForm from "@/pages/components/PopupForm";
import subForm from "./subForm";
import subFormReg from "./subFormReg";
import ViewMonitor from '../components/ViewMonitor.vue';
import { logout } from '@/services/user'
const columns = [
  {
    title: "序列号",
    dataIndex: "imei",
    ellipsis: true,
    width: "160px",
  },
  {
    title: "通道号",
    dataIndex: "channel",
    width: 80,
  },
  {
    title: "监控名称",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "单位名称",
    dataIndex: "customer",
    ellipsis: true,
  },
  {
    title: "单位地址",
    dataIndex: "customerAddr",
    scopedSlots: { customRender: "description" },
    ellipsis: true,
  },
  {
    title: "状态",
    width: 80,
    // dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: "240px",
  },
];

// eslint-disable-next-line no-unused-vars
import { dataSource as ds, ys7 } from "@/services";
import store from "@/store/index";
import qs from 'qs'
export default {
  name: "UserList",
  components: { StandardTable, subForm, PopupForm, subFormReg, ViewMonitor },
  data() {
    return {
      advanced: true,
      showForm: false,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      total: 0,
      pagination: {
        current: 1,
        pageSize: 13,
      },
      sftitle: undefined,
      sfdata: undefined,
      editmode: false,
      editmodekey: "",
      keyword: "",
      visibleConfirm: false,
      visibleConfirmSn: "",
      visibleConfirmId: "",
      showFaultCodeForm: false,
      showFaultCodeFormV: false,
      sfctitle: "故障编码",
      deviceModelId: null,
      visibleRegedit: null,
      visibleMonitor: false,
      monitorId: "",
      previewVisible: false,
      deviceimg_prev: "",
      captureLock: false,
    };
  },
  authorize: {
    // deleteRecord: 'delete'
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      // this.$message.info("update");
      ds.getVideoList({
        pageIndex: this.pagination.current,
        pageSize: this.pagination.pageSize,
        keyword: this.keyword,
      })
        .then((res) => {
          console.log("getVideoList", res);
          if (res.data.meta.success) {
            console.log("dataSource", res.data.data.list);
            this.dataSource = res.data.data.list.records.map((item) => {
              return {
                ...item,
                key: item.id,
                preview: null,
              };
            });
            this.getCaptureList(this.dataSource);
            this.total = res.data.data.list.total;
          } else {
            if(res.data.meta.code === 1006) {
              this.$message.error('获取数据出错：登录已过期，请重新登录');
              logout();
              this.$router.push('/login')
            } else {
              this.$message.error('获取数据出错：' + res.data.meta.msg);
            }
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    onSearch() {
      // this.$message.info("onSearch");
      this.update();
    },
    deleteRecord(key) {
      console.log("deleteRecord", key);
      this.visibleConfirm = true;
      // this.dataSource = this.dataSource.filter(item => item.key !== key)
      // this.selectedRows = this.selectedRows.filter(item => item.key !== key)
      this.visibleConfirmSn = key.imei;
      this.visibleConfirmId = key.id;
    },
    confirmDelete() {
      ds.deleteVideo(this.visibleConfirmId)
        .then((res) => {
          console.log("deleteVideo: ", res);
          if (res.data.meta.success) {
            this.$message.success("删除成功");
            this.update();
          } else {
            this.$message.error(res.data.meta.msg);
          }
        })
        .catch((err) => {
          console.log("deleteVideoErr: ", err);
        });
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    onClear() {
      this.$message.info("您清空了勾选的所有行");
    },
    onStatusTitleClick() {
      this.$message.info("你点击了状态栏表头");
    },
    onChange(e) {
      // this.$message.info('表格状态改变了')
      console.log("onChange", e);
      const { current } = e;
      this.pagination.current = current;
      this.update();
    },
    onSelectChange() {
      this.$message.info("选中行改变了");
    },
    addNew() {
      this.sftitle = undefined;
      this.sfdata = undefined;
      this.editmode = false;
      this.showForm = true;
    },
    hideForm(flag) {
      let newForm = this.$refs.newForm;
      console.log("hideForm", newForm);
      newForm.resetFields();
      this.$nextTick(() => {
        this.showForm = false;
        if(flag) {
          this.update();
        }
      })
    },
    editForm(record) {
      console.log("editForm", record);
      let newForm = this.$refs.newForm;
      ds.getVideoInfo(record.id).then(res => {
        console.log('getVideoInfo', res);
        if(res.data.meta.success) {
          this.sftitle = "编辑监控";
          this.showForm = true;
          this.$nextTick(() => {
            newForm.loadForm(res.data.data.data);
          })
        } else {
          this.$message.error('获取数据出现错误：', res.data.meta.msg);
        }
      }).catch(err => {
        this.$message.error('获取数据出现错误：', err);
      })
    },
    viewRecord(record) {
      console.log("viewRecord", record);
      this.monitorId = record.id;
      this.visibleMonitor = true;
    },
    async getCapture(serial, channel) {
      try {
        let token = await store.dispatch("token/getYStoken");
        const param = {
          accessToken: token,
          deviceSerial: serial,
          channelNo: channel,
        };
        const res = await ys7.deviceCapture(qs.stringify(param));
        console.log("captureRecord res", res);
        if (res.data.code === '200') {
          return(res.data.data.picUrl);
        }
      } catch (err) {
        console.error(err);
      }
      throw new Error('获取失败');
    },
    async captureRecord(record) {
      if(this.captureLock) return;
      this.captureLock = true;
      try {
        let token = await store.dispatch("token/getYStoken");
        console.log("captureRecord", record, token);
        const res = await this.getCapture(record.imei, record.channel);
        console.log("captureRecord res", res);
        if (res) {
          this.handlePreview(res);
        }
      } catch (err) {
        console.error(err);
        this.$message.error('获取预览出错，设备可能已离线。');
      }
      this.captureLock = false;
    },
    async getCaptureList(list) {
      console.log("getCaptureList", list);
      for(let ele of list) {
        const res = await this.getCapture(ele.imei, ele.channel)
        if(res) {
          ele.preview = res;
        }
      }
    },
    CloseFaultCodeForm() {
      this.showFaultCodeForm = false;
      setTimeout(()=>{
        this.showFaultCodeFormV = false;
      }, 300)
    },
    handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    },
    onSearchChange() {
      //
    },
    regedit() {
      this.visibleRegedit = true;
    },
    onRegedit() {
      this.visibleRegedit = false;
    },
    handlePreview(path) {
      console.log("handlePreview", path);
      this.deviceimg_prev = path;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    test() {
      ds.getIndexVideo().then(res => {
        console.log('getIndexVideo', res);
      })
    }
  },
};
</script>

<style lang="less" scoped>
.monitor_page {
  padding: 6px;
  margin-top: 7px;
  // margin-bottom: 20px;
  background: rgba(0, 20, 74, 0.8);
  border: 18px solid #027cc3;
  border-image:url(../../assets/img/card-b.png) 18 round;
  box-shadow: 0px 0px 25px 0px rgba(0, 138, 255, 0.4);
  color: #00effc;
  :deep(.ant-table) {
    color: #fff;
  }
  :deep(.ant-table-thead) {
    > tr {
      > th {
        background-color: #02215e;
        color: #00f9ff;
        border-bottom: 0px;
      }
    }
  }
  :deep(.ant-empty-normal) {
    // min-height: 190px;
    background-color: #fff0;
    color: #00f8fe;
  }
  :deep(.ant-table-tbody) {
    > tr {
      > td {
        border-bottom: 1px solid #fff2;
      }
      &:hover {
        > td {
          background-color: #fff2;
        }
      }
    }
  }
  :deep(.ant-table-placeholder) {
    background-color: #fff0;
    color: #00f9ff;
  }

  :deep(.ant-table-pagination) {
    .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
      background-color: #30a7f8;
      border: 1px solid #027cc3;
    }
    .ant-pagination-item {
      background-color: #30a7f8;
      border: 1px solid #027cc3;
    }
    .ant-pagination-item a {
      color: #2226;
    }
    .ant-pagination-item a:hover {
      color: #2229;
    }
    .ant-pagination-item-active a {
      color: #000e;
    }
  }

  .table_ctrl {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .operator2_search {
      width: 300px;
      :deep(.ant-input) {
        background-color: #021548;
        border-color: #0f4583;
        color: #fff;
      }
      :deep(.ant-input-clear-icon) {
        color: #fff6;
        &:hover {
          color: #fff;
        }
      }
    }
    .operator2_select {
      background-color: #021548;
      border-color: #0f4583;
      color: #fff;
      :deep(.ant-input) {
        background-color: #021548;
        border-color: #0f4583;
        color: #fff;
      }
      :deep(.ant-select-selection) {
        background-color: #021548;
        border-color: #0f4583;
        color: #fff;
      }
      :deep(.ant-select-selection__clear) {
        background-color: #0000;
        color: #fff6;
      }
      :deep(.ant-select-selection__clear:hover) {
        background-color: #0000;
        color: #fff;
      }
      :deep(.ant-cascader-picker-clear) {
        background-color: #0000;
        color: #fff6;
      }
      :deep(.ant-cascader-picker-clear:hover) {
        background-color: #0000;
        color: #fff;
      }
    }
    .operator {
      display: flex;
      align-items: center;
    }
  }
  .search {
    margin-bottom: 54px;
  }
  .fold {
    width: calc(100% - 216px);
    display: inline-block;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  // :deep(.ant-table{
  //   min-height: 740px;
  // }
  // :deep(.ant-empty-normal{
  //   min-height: 740px;
  // }
}
</style>
