var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"monitor_page"},[_c('sub-form',{ref:"newForm",attrs:{"visible":_vm.showForm,"title":_vm.sftitle,"formdata":_vm.sfdata},on:{"cancel":_vm.hideForm,"create":function($event){return _vm.hideForm(true)}}}),_c('sub-form-reg',{ref:"subformreg",attrs:{"visible":_vm.visibleRegedit},on:{"cancel":() => _vm.visibleRegedit = false,"create":_vm.onRegedit}}),(_vm.visibleMonitor)?_c('view-monitor',{attrs:{"id":_vm.monitorId},on:{"cancel":() => _vm.visibleMonitor = false}}):_vm._e(),_c('div',{staticClass:"table_ctrl"},[_c('a-space',{staticClass:"operator"},[_c('b',{staticStyle:{"font-size":"24px"}},[_vm._v("监控管理")]),_c('span',[_vm._v("（ 共 "+_vm._s(_vm.total)+" 条数据 ）")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_c('a-icon',{attrs:{"type":"plus-circle","theme":"filled"}}),_vm._v("新增监控")],1),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.regedit}},[_vm._v("注册萤石")])],1),_c('a-space',{staticClass:"operator2"},[_c('a-select',{staticClass:"operator2_select",staticStyle:{"width":"120px"},attrs:{"placeholder":"状态","allowClear":""},on:{"change":(e) => _vm.onSearchChange('status', e)}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" 在线 ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 离线 ")])],1),_c('a-input-search',{staticClass:"operator2_search",attrs:{"placeholder":"输入序列号、监控名称","allow-clear":""},on:{"search":_vm.onSearch},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('a-button',{attrs:{"slot":"enterButton","type":"primary","icon":"search"},slot:"enterButton"},[_vm._v(" 搜索 ")])],1)],1)],1),_c('standard-table',{attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource,"pagination":{
      total: _vm.total,
      pageSize: _vm.pagination.pageSize,
    }},on:{"clear":_vm.onClear,"change":_vm.onChange,"selectedRowChange":_vm.onSelectChange},scopedSlots:_vm._u([{key:"description",fn:function({ text }){return _c('div',{},[_vm._v(" "+_vm._s(text)+" ")])}},{key:"status",fn:function({ record }){return _c('div',{},[(record.status === 1)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" 在线")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v("离线")])])}},{key:"action",fn:function({ record }){return _c('div',{},[_c('a',{staticStyle:{"margin-right":"8px"},attrs:{"disabled":record.preview === null},on:{"click":function($event){return _vm.handlePreview(record.preview)}}},[_vm._v(" 预览 ")]),_c('a',{staticStyle:{"margin-right":"8px"},on:{"click":function($event){return _vm.viewRecord(record)}}},[_c('a-icon',{attrs:{"type":"zoom-in"}}),_vm._v(" 查看 ")],1),_c('a',{staticStyle:{"margin-right":"8px"},on:{"click":function($event){return _vm.editForm(record)}}},[_c('a-icon',{attrs:{"type":"edit"}}),_vm._v("编辑 ")],1),_c('a',{staticStyle:{"margin-right":"8px"},on:{"click":function($event){return _vm.deleteRecord(record)}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v("删除 ")],1)])}}])},[_c('template',{slot:"statusTitle"},[_c('a-icon',{attrs:{"type":"info-circle"},nativeOn:{"click":function($event){return _vm.onStatusTitleClick.apply(null, arguments)}}})],1)],2),_c('popup-form',{attrs:{"visible":_vm.visibleConfirm,"width":460,"maskClosable":false,"title":"确定删除？","oktext":"确定","destroyOnClose":true},on:{"cancel":() => {
        this.visibleConfirm = false;
      },"ok":() => {
        this.visibleConfirm = false;
        this.confirmDelete();
      }}},[_vm._v(" 确定删除监控 "),_c('span',{staticStyle:{"color":"#1b79d7"}},[_vm._v(_vm._s(_vm.visibleConfirmSn))]),_vm._v(" ？ ")]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.deviceimg_prev}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }