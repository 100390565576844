<template>
  <!-- <a-modal
      :visible="visible"
      title='新增型号'
      okText='提交'
      @cancel="() => { $emit('cancel') }"
      @ok="() => { $emit('create') }"
    > -->

  <popup-form
    :width="850"
    :visible="visible"
    :title="title"
    :maskClosable="false"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
    @ok="onSubmit"
  >
    <a-form
      class="class_newmonitro_form"
      layout="vertical"
      :form="form"
      @submit="submitForm"
    >
      <a-divider orientation="left">
        <a-icon type="book" style="color: #069eff" />
        设备信息
      </a-divider>

      <a-row class="form-row" type="flex" justify="space-around">
        <a-col :sm="{ span: 7 }">
          <a-form-item label="序列号">
            <a-input
              placeholder="请输入"
              v-decorator="[
                'formdata.imei',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :sm="{ span: 7 }">
          <a-form-item label="监控名称">
            <a-input
              placeholder="请输入"
              v-decorator="[
                'formdata.name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :sm="{ span: 7 }">
          <a-form-item label="通道号">
            <a-input
              placeholder="请输入"
              v-decorator="[
                'formdata.channel',
                {
                  rules: rules_number,
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row" type="flex" justify="space-around">
        <a-col :sm="{ span: 7 }">
          <a-form-item label="监控地址">
            <a-input
              placeholder="请输入"
              v-decorator="[
                'formdata.url',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :sm="{ span: 7 }">
          <a-form-item label="回放地址">
            <a-input
              placeholder="请输入"
              v-decorator="[
                'formdata.urlRec',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :sm="{ span: 7 }">
          <a-form-item label="云回放地址">
            <a-input
              placeholder="请输入"
              v-decorator="[
                'formdata.urlCloud',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row" type="flex" justify="space-around">
        <a-col :sm="{ span: 7 }">
          <a-form-item label="高清直播地址">
            <a-input
              placeholder="请输入"
              v-decorator="[
                'formdata.urlHd',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :sm="{ span: 7 }">
          <a-form-item label="验证码">
            <a-input
              placeholder="请输入"
              v-decorator="[
                'formdata.code',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                      whitespace: true,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :sm="{ span: 7 }">
          <a-form-item label="备注">
            <a-input placeholder="请输入" v-decorator="['formdata.remarks']" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider orientation="left">
        <a-icon type="book" style="color: #069eff" />
        单位名称
      </a-divider>

      <a-row class="form-row" type="flex" justify="space-around">
        <a-col :sm="{ span: 7 }">
          <a-form-item label="单位名称">
            <a-select
              placeholder="请选择"
              v-decorator="[
                'formdata.customerId',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                      whitespace: true,
                    },
                  ],
                },
              ]"
              show-search
              :options="customer_options"
              @focus="customer_focus"
              @change="customer_change"
            />
          </a-form-item>
        </a-col>

        <a-col :sm="{ span: 7 }">
          <a-form-item label="单位地址">
            <a-input disabled placeholder="" v-decorator="['formdata.place']" />
          </a-form-item>
        </a-col>

        <a-col :sm="{ span: 7 }">
          <a-form-item label="" :style="{ visibility: 'hidden' }">
            <a-input placeholder="请输入" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-divider orientation="left">
        <a-icon type="book" style="color: #069eff" />
        关联设备
      </a-divider>
      <a-row
        class="form-row"
        type="flex"
        justify="space-around"
        style="margin-bottom: 10px"
      >
        <a-col :sm="{ span: 7 }"> 设备名称 </a-col>
        <a-col :sm="{ span: 7 }"> 设备型号 </a-col>
        <a-col :sm="{ span: 7 }"> 设备ID </a-col>
        <a-col :sm="{ span: 1 }"></a-col>
      </a-row>
      <a-row
        v-for="(item, index) in formdata.deviceList"
        :key="item.deviceId"
        class="form-row"
        style="margin-top: 10px"
        type="flex"
        justify="space-around"
      >
        <a-col :sm="{ span: 7 }">
          <a-select
            placeholder="请选择"
            :value="item.deviceModelId"
            show-search
            :options="model_option"
            @focus="model_focus"
            @change="(v) => model_change(v, item)"
          />
        </a-col>

        <a-col :sm="{ span: 6 }">
          <a-input disabled placeholder="" :value="item.deviceModelName" />
        </a-col>

        <a-col :sm="{ span: 6 }">
          <a-select
            :placeholder="device_options.length === 0 ? '无设备' : '请选择'"
            :disabled="device_options.length === 0"
            :value="item.deviceId"
            :options="device_options"
            @change="(v) => device_change(v, item)"
          />
        </a-col>
        <a-col :sm="{ span: 1 }">
          <a
            :class="{ hide: index === 0 }"
            style="color: red; line-height: 32px"
            @click="deleteItem(item)"
          >
            删除
          </a>
        </a-col>
      </a-row>
      <a-row
        v-if="formdata.deviceList.length < 3"
        class="form-row"
        type="flex"
        justify="space-around"
      >
        <a-col :sm="{ span: 23 }">
          <a-button
            class="addbtn"
            style="width: 100%; margin-top: 16px; margin-bottom: 8px"
            type="dashed"
            icon="plus"
            @click="newBondDevice"
            >新增关联设备</a-button
          >
        </a-col>
      </a-row>
    </a-form>
  </popup-form>

  <!-- </a-modal> -->
</template>

<script>
import PopupForm from "../components/PopupForm.vue";
import { dataSource as ds } from "@/services";
export default {
  components: { PopupForm },
  name: "subForm",
  props: {
    title: { type: String, default: "新增监控" },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      formdata: {
        imei: "",
        name: "",
        remarks: "",
        url: "",
        urlCloud: "",
        urlHd: "",
        urlRec: "",
        customerId: "",
        code: "",
        channel: 1,
        deviceList: [
          {
            deviceModelId: undefined,
            deviceModelName: undefined,
            deviceId: undefined,
          },
        ],
      },
      monitorId: "",
      customer_options: [],
      model_option: [],
      device_options: [],
      keyword: "",
      conditions: {},
      total: 0,
      rules_number: [
        // {
        //   type: 'number',
        //   required: true,
        //   whitespace: true,
        //   message: "请输入数字",
        //   transform: v => Number(v),
        // },
        {
          required: true,
          message: "请输入数字",
          pattern: /^[1-9][0-9]*$/,
        },
        {
          validator(rule, value) {
            let v = parseInt(value);
            return (v < 256) && (v > 0)
          },
          message: '应在0到256之间',
        }
      ],
    };
  },
  expose: ["resetFields"],
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    resetFields() {
      console.log("resetFields");
      this.formdata.deviceList = [
        {
          deviceModelId: undefined,
          deviceModelName: undefined,
          deviceId: undefined,
        },
      ];
      this.monitorId = "";
      this.form.resetFields();
    },
    async customer_focus() {
      if (this.customer_options.length > 0) {
        return;
      }
      let res = await ds.getCustomerList();
      console.log("getCustomerList", res);
      if (res.data.meta.success) {
        console.log("dataSource", res.data.data.list);
        this.customer_options = res.data.data.list.list.map((i) => {
          return {
            title: i.customerName,
            value: i.customerId,
            place: i.customerAddress,
          };
        });
      }
    },
    customer_change(e) {
      let found = this.customer_options.find((i) => i.value === e);
      if (found) {
        this.form.setFieldsValue({
          "formdata.place": found.place,
        });
      }
    },
    submitForm(e) {
      console.log("submitForm", e);
    },
    async loadForm(data) {
      console.log("loadForm", data);
      this.resetFields();
      let copy = { ...data };
      let deviceList = copy.deviceList;
      delete copy.deviceList;
      this.monitorId = copy.id;
      Object.keys(copy).map((key) => {
        if (this.formdata[key] !== undefined) {
          console.log("loadForm", key, copy[key]);
          // this.formdata[key] = copy[key];
          let formKey = `formdata.${key}`;
          let field = {};
          field[formKey] = copy[key];
          this.form.setFieldsValue(field);
        }
      });
      await this.customer_focus();
      if (copy.customerId) {
        this.customer_change(copy.customerId);
      }
      if (deviceList.length > 0) {
        this.formdata.deviceList = deviceList.map((item) => {
          return {
            deviceModelId: item.deviceModelId,
            deviceModelName: undefined,
            deviceId: item.deviceId,
          };
        });
        this.deivce_focus(undefined, 1, 20);
        await this.model_focus();
        this.formdata.deviceList.forEach((item) => {
          let found = this.model_option.find(
            (i) => i.value === item.deviceModelId
          );
          if (found) {
            item.deviceModelName = found.title;
          }
        });
      }
    },
    newBondDevice() {
      let lastDevice = this.formdata.deviceList.slice(-1)[0];
      console.log("newBondDevice", lastDevice);
      if (lastDevice.deviceId !== undefined) {
        this.formdata.deviceList.push({
          deviceModelId: undefined,
          deviceModelName: undefined,
          deviceId: undefined,
        });
      }
    },
    deleteItem(data) {
      console.log("deleteItem", data, this.customer_options);
      this.formdata.deviceList = this.formdata.deviceList.filter(
        (item) => item.deviceId !== data.deviceId
      );
    },
    async model_focus() {
      if (this.model_option.length > 0) {
        return;
      }
      try {
        let res = await ds.deviceModelList();
        console.log("deviceModelList", res);
        if (res.data.meta.success) {
          console.log("dataSource", res.data.data.list);
          this.model_option = res.data.data.list.list.map((i) => {
            return {
              title: i.deviceModelName,
              value: i.deviceModelId,
              id: i.deviceModelType,
              label: i.deviceModelName,
            };
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    model_change(v, item) {
      let found = this.model_option.find((i) => i.value === v);
      console.log("deivce_change", v, item, found);
      if (found) {
        item.deviceModelId = v;
        item.deviceModelName = found.id;
        item.deviceId = undefined;
        this.deivce_focus(item.deviceModelName);
        // item.deviceModelName = v;
      }
    },
    async deivce_focus(keyword, index = 1, size = 10) {
      const { conditions } = this;
      // console.log('predeviceList', pageSize)
      try {
        let res = await ds.deviceList({
          pageIndex: index,
          pageSize: size,
          keyword: keyword,
          ...conditions,
        });
        console.log("deviceList", res);
        const { list, total } = res.data.data.list;
        this.total = total;
        this.device_options = list.map((i) => {
          return {
            title: i.deviceSn,
            value: i.deviceId,
          };
        });
      } catch (e) {
        console.error(e);
      }
    },
    device_change(v, item) {
      console.log("device_change", v, item);
      item.deviceId = v;
    },
    onSubmit() {
      console.log("onSubmit");
      this.addNewForm();
    },
    addNewForm() {
      let form = this.form;
      // console.log("newform", form);
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        values.formdata.remarks = values.formdata.remarks ?? "";
        values.formdata.deviceList = [];
        this.formdata.deviceList.forEach((ele) => {
          if (ele.deviceId !== undefined) {
            values.formdata.deviceList.push(ele.deviceId);
          }
        });
        console.log("newform1", values);
        if (this.monitorId) {
          console.log("Received values of form: ", values);
          delete values.place;
          ds.updateVideo({
            id: this.monitorId,
            ...values.formdata,
          })
            .then((res) => {
              console.log("undateVideo: ", res);
              if (res.data.meta.success) {
                this.$message.success("添加成功");
                // form.resetFields();
                // this.showForm = false;
                // this.update();
                this.$emit("create");
              } else {
                this.$message.error(res.data.meta.msg);
              }
            })
            .catch((err) => {
              console.log("addVideo Err: ", err);
            });
        } else {
          console.log("Received values of form: ", values);
          delete values.place;
          ds.addVideo(values.formdata)
            .then((res) => {
              console.log("addVideo: ", res);
              if (res.data.meta.success) {
                this.$message.success("添加成功");
                // form.resetFields();
                // this.showForm = false;
                // this.update();
                this.$emit("create");
              } else {
                this.$message.error(res.data.meta.msg);
              }
            })
            .catch((err) => {
              console.log("addVideo Err: ", err);
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.class_newmonitro_form {
  .ant-form-item {
    margin-bottom: 0;
  }
  :deep(.ant-divider) {
    margin: 5px 0;
    .ant-divider-inner-text {
      color: #0184ff;
      i {
        margin-right: 5px;
      }
      ::after {
        border-color: #0084ff;
      }
    }
  }
}

.hide {
  visibility: hidden;
}
.none {
  display: none;
}
</style>
