var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('popup-form', {
    attrs: {
      "centered": "",
      "visible": _vm.visible,
      "title": _vm.title,
      "maskClosable": false
    },
    on: {
      "cancel": function cancel() {
        _vm.$emit('cancel');
      },
      "ok": _vm.create
    }
  }, [_c('a-form', {
    attrs: {
      "layout": "vertical",
      "form": _vm.form
    },
    on: {
      "submit": _vm.submitForm
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "序列号"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['regeditys.imei', {
        initialValue: _vm.formdata.imei,
        rules: [{
          required: true,
          message: '请输入序列号'
        }]
      }],
      expression: "[\n          'regeditys.imei',\n          {\n            initialValue: formdata.imei,\n            rules: [{ required: true, message: '请输入序列号' }],\n          },\n        ]"
    }]
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "验证码"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['regeditys.code', {
        initialValue: _vm.formdata.code,
        rules: [{
          required: true,
          message: '请输入验证码'
        }]
      }],
      expression: "[\n          'regeditys.code',\n          {\n            initialValue: formdata.code,\n            rules: [{ required: true, message: '请输入验证码' }],\n          },\n        ]"
    }]
  })], 1), _c('a-button', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "primary",
      "html-type": "submit"
    }
  }, [_vm._v(" Submit ")])], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };